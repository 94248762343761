<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Partenaire : {{ partner.nom }}</h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow rounded">
                  <div class="card-body">
                    <img :src="partner.url" width="100%" alt="" />
                  </div>
                </div>
                <div class="card shadow rounded mt-2">
                  <div class="card-header flex-wrap border-0 pt-6 pb-0">
                    <div class="card-title">
                      Information sur l'utilisateur
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Jour ouverture 1</th>
                        <td class="col-sm-6">{{ partner.jour_ouverture_1 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Jour ouverture 2</th>
                        <td class="col-sm-6">{{ partner.jour_ouverture_2 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure debut semaine</th>
                        <td class="col-sm-6">{{ partner.heure_debut_week }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure fin semaine</th>
                        <td class="col-sm-6">{{ partner.heure_fin_week }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure debut weekend</th>
                        <td class="col-sm-6">
                          {{ partner.heure_debut_weekend }}
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure fin weekend</th>
                        <td class="col-sm-6">
                          {{ partner.heure_fin_weekend }}
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure pause debut</th>
                        <td class="col-sm-6">
                          {{ partner.heure_pause_debut }}
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Heure pause fin</th>
                        <td class="col-sm-6">{{ partner.heure_pause_fin }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card shadow rounded">
                  <div class="card-header flex-wrap border-0 pt-6 pb-0">
                    <div class="card-title">
                      Information sur l'utilisateur
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ partner.user.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prenoms</th>
                        <td class="col-sm-6">{{ partner.user.prenoms }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Email</th>
                        <td class="col-sm-6">{{ partner.user.email }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Numéros de téléphone</th>
                        <td class="col-sm-6">{{ partner.user.telephone_1 }}</td>
                      </tr>
                      <tr class="row" v-if="partner.user.telephone_2">
                        <th class="col-sm-6">Second Numéros de téléphone</th>
                        <td class="col-sm-6">{{ partner.user.telephone_2 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Type d'utilisateur</th>
                        <td class="col-sm-6">{{ partner.user.type_compte }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card shadow rounded mt-2">
                  <div class="card-header flex-wrap border-0 pt-6 pb-0">
                    <div class="card-title">
                      Informations générales
                    </div>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ partner.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Catégorie</th>
                        <td class="col-sm-6">{{ partner.categorie.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Zone</th>
                        <td class="col-sm-6">
                          <h3
                            class="text text-uppercase badge badge-info mr-2"
                            v-for="(ville, index) in partner.villes"
                            :key="index"
                          >
                            {{ ville.nom }}
                          </h3>
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Adresse</th>
                        <td class="col-sm-6">{{ partner.adresse }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Longitude</th>
                        <td class="col-sm-6">{{ partner.long }}°</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Latitude</th>
                        <td class="col-sm-6">{{ partner.lat }}°</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Show",
  data() {
    return {
      initialize: "partener/" + this.$route.params.id,
      advertising: [],
      partner: {
        categorie: {},
        zone: {},
        user: {}
      }
    };
  },
  methods: {},
  mounted() {
    let vm = this;
    ApiService.get(this.initialize).then(function(response) {
      vm.partner = response.data.data;
      vm.demandes = response.data.demandes;
    });
  }
};
</script>

<style scoped></style>
